* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul, ol{
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
}


/*@media (max-width: 426px) {*/
/*  html {*/
/*    font-size: 87.5%;*/
/*  }*/
/*}*/






body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("https://gruposvd.com.br/img/design/Banner-Grupo-SVD.jpg");
  background-size: cover;

  background-repeat: no-repeat;

  width: 100vw;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
