.mobileContainer {
  position: absolute;
  top: 0;
  right: 0;

  z-index: 200;

  background-color: #1F4E79;

  //text-align: center;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  color: white;

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  nav {
    padding-right: 40px;
  }


  li {
    padding: 2px;
    margin: 10px 3px;
    font-size: 1.3rem;
  }
}